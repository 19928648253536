import {
    createSlice
} from '@reduxjs/toolkit';
import {
    authSliceState as initialState
} from '~config/constants/default-values';
import {
    accountApi
} from '~screens/account/services/accountApi';
import {
    authApi
} from '../services/authApi';

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (
            state,
            action
        ) => {
            state.user
                = action.payload
        },

        setDraftLoaded: (
            state,
            action
        ) => {
            state.draftLoaded
                = action.payload
        },
        updateUser: (
            state: any,
            action
        ) => {
            state.user = {
                ...state.user,
                userDto: {
                    ...state.user.userDto,
                    ...action.payload,
                }
            }
        },
        
        incrementUserOrders: (
            state: any
        ) => {
            let ordersCount=(state.user.ordersCount||0)+1

            state.user = {
                ...state.user,
                ordersCount
            }
        },

        clearUser: (state) => {
            state.user = null
        },
        setSubscriberUserInfo: (state, action) => {
            state.subscriberUserInfo = action.payload
        },
        setSavedPath: (
            state,
            action
        ) => {
            state.savedPath
                = action.payload
        },
        setResetPasswordProps: (
            state,
            action
        ) => {

            state.resetPasswordProps
                = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                authApi.endpoints.login.matchFulfilled,
                (state, { payload }) => {

                    state.user = payload
                }
            )
            .addMatcher(
                authApi.endpoints.register.matchFulfilled,
                (state, { payload }) => {

                    state.user = payload
                }
            )
            .addMatcher(
                authApi.endpoints.createVisitor.matchFulfilled,
                (state, { payload }) => {

                    const {
                        message,
                        ...payloadRest
                    } = payload

                    state.user = payloadRest
                }
            )
            .addMatcher(
                accountApi.endpoints.updateUserDetails.matchFulfilled,
                (state: any, { payload }) => {

                    const {
                        firstName,
                        lastName,
                    } = payload?.data

                    state.user
                        = {
                        ...state.user,
                        userDto: {
                            ...state.user.userDto,
                            firstName,
                            lastName,
                        }
                    }
                }
            )
    }
});

export const {
    clearUser,
    setUser,
    setDraftLoaded,
    incrementUserOrders,
    setSubscriberUserInfo,
    updateUser,
    setSavedPath,
    setResetPasswordProps,
} = authSlice.actions;

export default authSlice.reducer;