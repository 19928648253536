
export const Satoshi = {
    className: 'font-satoshi',
    style: {
        fontFamily: 'Satoshi'
    }
}

export const Public_Sans = {
    className: 'font-public-sans',
    style: {
        fontFamily: 'Public Sans'
    }
}

export const Barlow = {
    className: 'font-barlow',
    style: {
        fontFamily: 'Barlow'
    }
}


