import { GetSecretValueCommand, SecretsManagerClient } from '@aws-sdk/client-secrets-manager';
import moment, { Moment, unitOfTime } from 'moment';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import reactStringReplace from 'react-string-replace';
import enums from '~config/constants/enums';
import { deliveryDays, deliveryDaysOption, deliveryDaysStart } from '~config/constants/variables';
const countChars = (string: any, char: any) => {
  return string.length - string.replaceAll(char, '').length;
};
const getLastFiveDigitsFromObjectId = (objectId: string) => {
  if (typeof objectId !== 'string' || objectId.length !== 24) console.error('error~~~>', 'Invalid ObjectId format');
  return objectId?.substring(19);
};
const getMicrophonePermission = async () => {
  if ('MediaRecorder' in window) {
    try {
      const streamData = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });
      return {
        streamData
      };
    } catch (err) {
      toast.error(err.message);
      return {
        streamData: null
      };
    }
  } else {
    toast.error('The MediaRecorder API is not supported in your browser.');
    return {
      streamData: null
    };
  }
};
const mergeVideoAndAudio = async (videoURL: string, audioURL: string, duration: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const videoBlob = await fetch(videoURL).then(response => response.blob());
      const audioBlob = await fetch(audioURL).then(response => response.blob());
      const videoObjectURL = URL.createObjectURL(videoBlob);
      const audioObjectURL = URL.createObjectURL(audioBlob);
      const videoElement: any = document.createElement('video');
      const audioElement: any = document.createElement('audio');
      audioElement.volume = 0.001;
      videoElement.src = videoObjectURL;
      audioElement.src = audioObjectURL;
      await Promise.all([videoElement.play(), audioElement.play()]);
      const videoTrack = videoElement.captureStream().getVideoTracks()[0];
      const audioTrack = audioElement.captureStream().getAudioTracks()[0];
      setTimeout(() => {
        Promise.all([videoElement.pause(), audioElement.pause()]);
      }, duration);
      const mergedStream = new MediaStream();
      mergedStream.addTrack(videoTrack);
      mergedStream.addTrack(audioTrack);
      const mediaRecorder = new MediaRecorder(mergedStream);
      let recordedChunks: any = [];
      mediaRecorder.ondataavailable = event => {
        if (event.data.size > 0) {
          recordedChunks.push(event.data);
        }
      };
      mediaRecorder.onstop = () => {
        const mergedBlob = new Blob(recordedChunks, {
          type: 'video/mp4'
        });
        const mergedURL = URL.createObjectURL(mergedBlob);
        resolve({
          mergedURL,
          mergedBlob
        });
      };
      mediaRecorder.start();
      setTimeout(() => {
        mediaRecorder.stop();
      }, duration);
    } catch (error) {
      reject(error);
    }
  });
};
const getPermission = async (medias: Array<string>) => {
  if ('MediaRecorder' in window) {
    try {
      let streamData;
      let streams: any = [];
      if (medias.includes('video')) {
        const videoConstraints = {
          audio: false,
          video: true
        };
        const videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints);
        streams = [...streams, ...videoStream.getVideoTracks()];
      }
      if (medias.includes('audio')) {
        const audioConstraints = {
          audio: true
        };
        const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints);
        streams = [...streams, ...audioStream.getAudioTracks()];
      }
      streamData = new MediaStream(streams);
      return {
        streamData
      };
    } catch (err) {
      toast.error(err.message);
      return {
        streamData: null
      };
    }
  } else {
    toast.error('The MediaRecorder API is not supported in your browser.');
    return {
      streamData: null
    };
  }
};
const isValidId = (id: string) => {
  return id?.match?.(/^[0-9a-fA-F]{24}$/);
};
const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
const getSubstrings = (char1: string, char2: string, string: string) => {
  const regex = new RegExp(`\\${char1}(.*?)\\${char2}`, 'g');
  const paths: string[] = [];
  let match;
  while ((match = regex.exec(string)) !== null) {
    paths.push(match[1]);
  }
  return paths;
};
const fetchProp = (mainValue: string, data: any) => {
  try {
    let valueToReturn: any = mainValue;
    const paths = getSubstrings('[', ']', mainValue);
    paths?.map?.(path => {
      const keys = path?.split?.('.');
      let value = data;
      keys?.map?.(key => {
        if (key.includes('where')) {
          const comparisionPath = getSubstrings('(', ')', key)[0];
          let [comparisionKeys, comparisionValue, pathKeys]: any = comparisionPath?.split?.('*');
          comparisionKeys = comparisionKeys?.split?.('>');
          let matches = value?.filter?.((obj: any) => {
            let foundValue = obj;
            comparisionKeys.map((comparisionKey: any) => foundValue = foundValue[comparisionKey]);
            return foundValue == comparisionValue;
          });
          pathKeys = pathKeys.split('>');
          let commaSeparated = '';
          matches?.map?.((match: any) => {
            let localMatch = match;
            pathKeys?.map?.((pathKey: any) => {
              localMatch = localMatch[pathKey];
            });
            commaSeparated = localMatch + ', ' + commaSeparated;
          });
          valueToReturn = commaSeparated;
        } else if (key.includes('format')) {
          const format = getSubstrings('(', ')', key)[0];
          value = moment(value).tz('America/Chicago').format(format);
        } else if (key.includes('enum')) {
          const enumType = getSubstrings('(', ')', key)[0];
          value = enums[enumType][value];
        } else value = value?.[key];
      });
      valueToReturn = valueToReturn.replace(`[${path}]`, value);
    });
    if (valueToReturn.includes('==')) {
      const [leftHandSide, rest1] = valueToReturn.split('==');
      const [rightHandSide, rest2] = rest1.split('?');
      const [ifResult, elseResult] = rest2.split(':');
      if (leftHandSide == rightHandSide) valueToReturn = ifResult;else valueToReturn = elseResult;
    }
    valueToReturn = valueToReturn.replace(`undefined`, '');
    if (valueToReturn?.includes?.('||')) {
      let [value1, value2] = valueToReturn.split('||');
      value1 = value1.trim();
      value2 = value2.trim();
      valueToReturn = value1 || value2;
    }
    if (valueToReturn.includes('bool')) {
      return valueToReturn.replace('bool ', '') == 'true' || valueToReturn.replace('bool ', '') == '1' ? "Yes" : "No";
    }
    if (valueToReturn === '') return '--';
    return valueToReturn;
  } catch (error) {
    console.error('error~~~>', error);
    return '--';
  }
};
const timeDiference = (start: Moment, end: Moment, tickSize: unitOfTime.Diff) => {
  let difference = end.diff(start, tickSize);
  return difference;
};
const getRushFee = (deliveryDate: string) => {
  // return 0;

  const difference = timeDiference(moment(deliveryDate, 'DD/MM/YY').tz('America/Chicago', true), moment().add(deliveryDays - 1, 'days'), 'days');
  let fee = 0;
  if (difference > 0) fee = 20;
  for (let i = 0; i < difference; i++) {
    fee += 5;
  }
  return fee;

  // if (difference > 0)
  //     if (difference == 1) return 20;
  //     else return 20 + (difference - 1) * (deliveryDaysOption);
};
const getId = () => {
  return Math.random().toString(16).slice(2);
};
const getSongs = (songs: any, occasion?: boolean) => {
  const data = songs?.map((item: any) => {
    let itemToDistruct = item;
    if (occasion) itemToDistruct = item[`song`];
    const {
      name,
      audioFile,
      songMusicTraits,
      user
    } = itemToDistruct || {};
    return {
      title: name,
      song: audioFile,
      genre: songMusicTraits?.map?.((item: any) => item?.musicTrait?.name),
      artist: user?.stageName || `${user?.firstName || ''} ${user?.lastName || ''}`.trim(),
      artistId: user?._id
    };
  });
  return data;
};
const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
};
const downloadURI = async (uri: string, name: string) => {
  try {
    const response = await fetch(uri);
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error(error);
  }
};
const camelCaseToTitle = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
const findAddonIndex = (addonType: string) => {
  let addon = addonType.replace('-', '');
  return enums.addonTypeName.findIndex((item: string) => {
    return item.toLowerCase() == addon;
  });
};
const getExtension = (string: string) => {
  const parts = string.split('.');
  return '.' + parts[parts.length - 1];
};
const sentenceToBreadCrumb = (string: string) => {
  let breadCrumb = string.toLowerCase().replace(' ', '-');
  if (breadCrumb.slice(-1) == 's') return breadCrumb.slice(0, -1);
  return breadCrumb;
};
const findCharIndexes = (str: string, char: string) => {
  let indexes = [];
  for (let i = 0; i < str.length; i++) {
    if (char == str[i]) indexes.push(i);
  }
  return indexes;
};
const linkify = (str: string) => {
  let roundStartIndexes = findCharIndexes(str, '(');
  let roundEndIndexes = findCharIndexes(str, ')');
  let squareStartIndexes = findCharIndexes(str, '[');
  let squareEndIndexes = findCharIndexes(str, ']');
  const links: any = [];
  for (let i = 0; i < roundStartIndexes.length; i++) {
    const roundStart = roundStartIndexes[i];
    const roundEnd = roundEndIndexes[i];
    const squareStart = squareStartIndexes[i];
    const squareEnd = squareEndIndexes[i];
    const text = str.substring(roundStart + 1, roundEnd);
    const link = str.substring(squareStart + 1, squareEnd);
    links.push({
      text,
      link
    });
  }
  let newStr: any = str;
  for (let i = 0; i < links.length; i++) {
    const {
      text,
      link
    } = links[i];
    newStr = reactStringReplace(newStr, `(${text})[${link}]`, () => <a key={i} href={link} target="_blank" style={{
      margin: '0px 5px',
      color: '#EF7484',
      border: '0px solid red'
    }}>
                {text}
            </a>);
  }
  return newStr;
};
const lastIndexThatIncludes = (array: any[], prop: string, targetValue: any) => {
  for (let i = array.length; i >= 0; i--) {
    let object = array[i];
    if (object?.[prop]?.includes?.(targetValue)) {
      return i;
    }
  }
  return null;
};
const centerFace = (url: string, height = 400, width = 400, moreParams = '') => {
  const [prefix, path] = splitBy2(url, '/upload/');
  const [maybe_params, rest_of_path] = splitBy2(path || '', '/');
  const it_already_has_params = maybe_params.includes(',');
  const centerFaceParams = `c_fill,g_face,h_${height},w_${width}` + (moreParams ? ',' + moreParams : '');
  const str_params3 = it_already_has_params ? mergeCloudinaryParamsStrings(maybe_params, centerFaceParams) : centerFaceParams + '/' + maybe_params;
  const url2 = `${prefix}/upload/${str_params3}/${rest_of_path}`;
  const url_webp = url2.endsWith('.webp') ? url2 : url2.substring(0, url2.lastIndexOf('.')) + '.webp';
  return url_webp;
};
const shuffle = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
const toSecureLink = (url: string) => {
  if (url?.includes?.('http:')) return url?.replace?.('http:', 'https:');
  return url;
};
const negativeAmount = (amount: string) => {
  if (amount.includes('-')) {
    return '-' + amount.replace('-', '');
  }
  return amount;
};
const minZero = (amount: number) => {
  return amount >= 0 ? amount : 0;
};
const getSecret = async (awsAccessKey: any, secretAccessKey: any) => {
  const secret_name = 'env';
  const credentials = {
    accessKeyId: awsAccessKey,
    secretAccessKey: secretAccessKey
  };
  const client = new SecretsManagerClient({
    region: 'us-east-1',
    credentials
  });
  let response: any;
  try {
    response = await client.send(new GetSecretValueCommand({
      SecretId: secret_name,
      VersionStage: 'AWSCURRENT'
    }));
  } catch (error) {
    console.error('error~~~>', error);
  }
  return JSON.parse(response.SecretString);
};
function splitBy2(splited: string, splitBy: string): [string, string?] {
  const splitIndex = splited?.indexOf?.(splitBy);
  if (splitIndex === -1) {
    return [splited];
  }
  const firstPart = splited?.substring?.(0, splitIndex);
  const secondPart = splited?.substring?.(splitIndex + splitBy.length);
  return [firstPart, secondPart];
}
function fitSize(originalSize: number, targetSizesSorded: number[]): number {
  let chosenSize = targetSizesSorded[targetSizesSorded.length - 1];
  for (const size of targetSizesSorded) {
    if (originalSize < size) {
      chosenSize = size;
      break;
    }
  }
  return chosenSize;
}
function mergeCloudinaryParamsStrings(query1: string, query2: string) {
  const queryStringToObject = parseCloudinaryParamsString(query1);
  const queryStringToObject2 = parseCloudinaryParamsString(query2);
  const mergedObject = {
    ...queryStringToObject,
    ...queryStringToObject2
  };
  return formatCloudinaryParamsString(mergedObject);
}
const parseCloudinaryParamsString: (queryString: string) => Record<string, string> = (queryString: string) => {
  return queryString.split(',').reduce<Record<string, string>>((acc, pair) => {
    const lastIndex = pair.lastIndexOf('_');
    const key = pair.substring(0, lastIndex);
    const value = pair.substring(lastIndex + 1);
    acc[key] = value;
    return acc;
  }, {});
};
const formatCloudinaryParamsString: (queryParams: Record<string, string>) => string = queryParams => {
  return Object.entries(queryParams).map(([key, value]) => `${key}_${value}`).join(',');
};
const isFutureDate = (value: string) => {
  const [month, year] = value.split('/');
  const expiryDate = new Date(`${20 + year}-${month}-01`);
  return expiryDate > new Date();
};
const hash_53bit_cyrb53 = (str: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ h1 >>> 16, 2246822507);
  h1 ^= Math.imul(h2 ^ h2 >>> 13, 3266489909);
  h2 = Math.imul(h2 ^ h2 >>> 16, 2246822507);
  h2 ^= Math.imul(h1 ^ h1 >>> 13, 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};
const fetchWithToken = (access_token: string, url: string, options: any = {
  headers: {}
}) => {
  if (!options.headers) options.headers = {};
  if (access_token) {
    // if method has body
    options.headers['authorization'] = `Bearer ${access_token}`;
  }
  if (!options.method) options.method = 'GET';
  if (['POST', 'PUT', 'PATCH'].includes(options.method)) options.headers['Content-Type'] = 'application/json';
  return fetch(url, options);
};
const addNewlinesToLyrics = (lyrics: string | undefined): string => (lyrics || "")?.replace?.(/(?<=[^ \n]+ *\n)(\[?)(\w{5,10}( \d)?)(\:|\])( *\n|$)/gm, '\n$1$2$4$5')?.trim?.() || "";
function hide_contact_us() {
  useEffect(() => {
    try {
      const element_zsiq_float = (window as any).zsiq_float as HTMLUnknownElement;
      if (!(element_zsiq_float?.classList === null || element_zsiq_float?.classList === undefined)) {
        if (![].includes.call(element_zsiq_float.classList, 'hide_zsiq' as never)) element_zsiq_float?.classList.add("hide_zsiq");
      }
    } catch (e) {
      console.error(e);
    }
    return () => {
      try {
        const element_zsiq_float = (window as any).zsiq_float;
        if (!(element_zsiq_float?.classList === null || element_zsiq_float?.classList === undefined)) {
          if ([].includes.call(element_zsiq_float.classList, 'hide_zsiq' as never)) element_zsiq_float.classList.remove("hide_zsiq");
        }
      } catch (e) {
        console.error(e);
      }
    };
  });
}
const formatDate = (date: string): string => {
  const momentDate = moment(date);
  if (moment().diff(momentDate, 'days') < 7) {
    return momentDate.fromNow();
  }
  return momentDate.format('MM/DD/YYYY');
};
export { getId, minZero, shuffle, linkify, fitSize, splitBy2, getSongs, fetchProp, isValidId, getSecret, getRushFee, centerFace, formatTime, countChars, downloadURI, isFutureDate, getExtension, toSecureLink, timeDiference, getSubstrings, getPermission, negativeAmount, fetchWithToken, findAddonIndex, findCharIndexes, getRandomNumber, hash_53bit_cyrb53, camelCaseToTitle, mergeVideoAndAudio, sentenceToBreadCrumb, lastIndexThatIncludes, getMicrophonePermission, parseCloudinaryParamsString, mergeCloudinaryParamsStrings, formatCloudinaryParamsString, getLastFiveDigitsFromObjectId, addNewlinesToLyrics, hide_contact_us, formatDate };