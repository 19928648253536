import {
    isValidId
} from '~helper/index'
import {
    api
} from '~services/index'

export const orderApi
    = api.injectEndpoints({
        endpoints: (builder) => ({
            starter: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/order/starter`,
                        method: body?.existsInDB ?
                            'PUT'
                            :
                            'POST',
                        body,
                    }
                },
                invalidatesTags: (
                    _result,
                    _error,
                    {
                        occasion,
                        recipientRelationship,
                    }
                ) => {

                    let tags: any = [
                        'OrderDetail'
                    ]

                    if (!isValidId(occasion))
                        tags = [
                            ...tags,
                            "Occasion"
                        ]
                    if (!isValidId(recipientRelationship))
                        tags = [
                            ...tags,
                            "Relationship"
                        ]

                    return tags
                }
            }),


            starterEmailZoho: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/order/starter-email-zoho`,
                        method: 'POST',
                        body,
                    }
                },
            }),


            artist: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/order/artist`,
                        method: body?.existsInDB ?
                            'PUT'
                            :
                            'POST',
                        body,
                    }
                },
                invalidatesTags: ['OrderDetail']
            }),
            story: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/order/story`,
                        method: body?.existsInDB ?
                            'PUT'
                            :
                            'POST',
                        body,
                    }
                },
                invalidatesTags: ['OrderDetail']
            }),

            evaluateStory: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/order/evaluate-story`,
                        method: 'POST',
                        body,
                    }
                },
                // invalidatesTags: ['OrderDetail']
            }),

            generateAISong: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/aisong/generate-aisong`,
                        method: 'POST',
                        body,
                    }
                },
                invalidatesTags: ['AISongs']
            }),



            generateAISong2: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/aisong/generate-aisong2`,
                        method: 'POST',
                        body,
                    }
                },
                invalidatesTags: ['AISongs']
            }),

            getGenerateUpdateAISongLyrics: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/aisong/get-generate-update-aisong-lyrics`,
                        method: 'POST',
                        body,
                    }
                },
                invalidatesTags: []
            }),


            additionalAISongs: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/order/additional-aisongs`,
                        method: 'POST',
                        body,
                    }
                },
                invalidatesTags: ['OrderDetail']
            }),


            selectAISong: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/aisong/select-aisong`,
                        method: 'POST',
                        body,
                    }
                },
                invalidatesTags: []
            }),


            getOrderAISongs: builder.query({
                query: (body: any) => ({
                    url: `/api/aisong/get-aisongs`,
                    method: 'POST',
                    body,
                }),
                providesTags: ['AISongs'],
            }),

            getOrderAISongs2: builder.query({
                query: (body: any) => ({
                    url: `/api/aisong/get-aisongs2`,
                    method: 'POST',
                    body,
                }),
                providesTags: ['AISongs'],
            }),


            getOrderAISongs2Mu: builder.mutation({
                query: (body: any) => ({
                    url: `/api/aisong/get-aisongs2`,
                    method: 'POST',
                    body,
                }),
            }),

            orderDetailsMu: builder.mutation({
                query: (_id) => ({
                    url: `/api/order/order-details/${_id}`
                }),
            }),

            updateSongOrderDetails: builder.mutation({
                query: (body: any) => ({
                    url: `/api/order/update-song-order-details`,
                    method: 'POST',
                    body,
                }),
                invalidatesTags: ['OrderDetail']
            }),

            getAISongDuration: builder.mutation({
                query: ({ aisongid, version }: { aisongid: string, version: number }) => {
                    return {
                        url: `/api/aisong/get-aisong-duration?aisongid=${aisongid}&version=${version}`,
                        method: 'GET',
                    }
                },
            }),



            getLastDraft: builder.mutation({
                query: () => {
                    return {
                        url: `/api/order/last-draft`,
                        method: 'GET',
                    }
                },
            }),


            getPaypalClientId: builder.query({
                query: () => ({
                    url: `/api/paypal/paypal-client-id`,
                    method: 'GET',
                }),
                providesTags: [],
            }),

            paypalCreateOrder: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/paypal/create-order`,
                        method: 'POST',
                        body,
                    }
                },
                invalidatesTags: [
                ]
            }),


            AISongVersionLyrics: builder.query({
                query: (body: any) => ({
                    url: `/api/aisong/aisong-version-lyrics`,
                    method: 'POST',
                    body,
                }),
                providesTags: ['AISongs'],
            }),


            review: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/order/review`,
                        method: 'POST',
                        body,
                    }
                },
                invalidatesTags: [
                    'OrderDetail',
                    'PreviousOrders'
                ]
            }),
            orderMix: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/order/order-mix`,
                        method: 'POST',
                        body,
                    }
                },
                invalidatesTags: [
                    'OrderDetail',
                    'Order'
                ]
            }),
            clientChoice: builder.mutation({
                query: (body: any) => {
                    return {
                        url: `/api/order/client-choice`,
                        method: 'PATCH',
                        body,
                    }
                },
                invalidatesTags: [
                    'OrderDetail',
                    'Order'
                ]
            }),
            getCouponByName: builder.mutation({
                query: (couponName) => {
                    return {
                        url: `/api/coupon/coupon-by-name/${couponName}`,
                        method: 'GET',
                    }
                },
            }),
            getCouponByCode: builder.query({
                query: (couponCode) => {
                    return {
                        url: `/api/coupon/coupon-by-code/${couponCode}`,
                        method: 'GET',
                    }
                },
            }),
            getOrders: builder.query({
                query: (body) => ({
                    url: `/api/order/get-orders-client${(Object.keys(body).length?'?':'')+Object.keys(body).map(x=>x+'='+encodeURIComponent(body[x])).join('&')}`,
                }),
                providesTags: ['Order'],
            }),
            getPreviousOrders: builder.query({
                query: (addonTypeName) => ({
                    url: `/api/order/get-previous-orders/${addonTypeName}`,
                }),
                providesTags: ['PreviousOrders']
            }),
            orderDetails: builder.query({
                query: (_id) => ({
                    url: `/api/order/order-details/${_id}`
                }),
                providesTags: ['OrderDetail'],
            }),
            recipientRelationship: builder.query({
                query: () => ({
                    url: `/api/order/recipient-relationship`
                }),
                providesTags: ['Relationship'],
            }),
            musicTrait: builder.query({
                query: (body) => ({
                    url: `/api/music-trait`,
                    method: 'PATCH',
                    body,
                }),
                providesTags: ['Artist'],
            }),
            topic: builder.query({
                query: () => ({
                    url: `/api/topic`,
                }),
                providesTags: ['Topic'],
            }),
            previousOrderSummaries: builder.query({
                query: (orderId) => ({
                    url: `/api/payment/previous-order-summaries/${orderId}`,
                }),
                providesTags: ['PreviousOrderSummaries'],
            }),
            customOrderSummaries: builder.query({
                query: (orderId) => ({
                    url: `/api/payment/custom-order-summaries/${orderId}`,
                }),
                providesTags: ['CustomOrderSummaries'],
            }),
            extraCharge: builder.query({
                query: (orderId) => ({
                    url: `/api/order/extra-charge/${orderId}`,
                }),
                providesTags: ['ExtraCharge'],
            }),
            deleteExtraCharge: builder.mutation({
                query: (orderId) => {
                    return {
                        url: `/api/order/extra-charge/${orderId}`,
                        method: 'DELETE',
                    }
                },
                invalidatesTags: ['ExtraCharge']
            }),
            clientReview: builder.mutation({
                query: (body) => {
                    return {
                        url: `/api/order/client-review`,
                        method: 'POST',
                        body,
                    };
                },
            }),

            addSongComment: builder.mutation({
                query: (body) => {
                    return {
                        url: `/api/song-comments`,
                        method: 'POST',
                        body,
                    };
                },
                invalidatesTags: ['SongComments']
            }),
            addSongCommentSubscriber: builder.mutation({
                query: (body) => {
                    return {
                        url: `/api/song-comments/subscribe`,
                        method: 'POST',
                        body,
                    };
                },
            }),
            songComments: builder.query({
                query: ({ orderId, userEmail }) => {
                    return {
                        url: `/api/song-comments/${orderId}/?userEmail=${userEmail}`,
                    };
                },
                providesTags: ['SongComments']
            }),
        }),
    });

export const {
    useTopicQuery,
    useStoryMutation,
    useEvaluateStoryMutation,
    useGenerateAISongMutation,
    useGenerateAISong2Mutation,
    useSelectAISongMutation,
    useGetOrderAISongsQuery,
    useGetOrderAISongs2Query,
    useAISongVersionLyricsQuery,
    useGetOrdersQuery,
    useReviewMutation,
    useArtistMutation,
    useStarterMutation,
    usePaypalCreateOrderMutation,
    useStarterEmailZohoMutation,
    useGetGenerateUpdateAISongLyricsMutation,
    useMusicTraitQuery,
    useGetPaypalClientIdQuery,
    useExtraChargeQuery,
    useOrderMixMutation,
    useOrderDetailsQuery,
    useClientReviewMutation,
    useGetOrderAISongs2MuMutation,
    useGetAISongDurationMutation,
    useClientChoiceMutation,
    useGetCouponByNameMutation,
    useUpdateSongOrderDetailsMutation,
    useOrderDetailsMuMutation,
    useGetLastDraftMutation,
    useGetCouponByCodeQuery,
    useGetPreviousOrdersQuery,
    useDeleteExtraChargeMutation,
    useAdditionalAISongsMutation,
    useCustomOrderSummariesQuery,
    useRecipientRelationshipQuery,
    usePreviousOrderSummariesQuery,
    useAddSongCommentMutation,
    useAddSongCommentSubscriberMutation,
    useSongCommentsQuery,
} = orderApi