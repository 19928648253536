import InstantSong from "~screens/order/step4/InstantSong"

const searchTimeout = 500
const deliveryDaysStart = 4
const deliveryDaysOption = 4
const deliveryDays = deliveryDaysStart + deliveryDaysOption
const instantSongVersion: number = 2

export {
    deliveryDays,
    searchTimeout,
    deliveryDaysStart,
    deliveryDaysOption,
    instantSongVersion,
}